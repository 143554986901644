// @ts-check
import { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import createSpeechServicesPonyfill from 'web-speech-cognitive-services';

const SUBSCRIPTION_KEY = 'FAfGmUEThUac24hJXoLXV6kctVmfiGyitKlMV87ZpHexnTNvMZfCJQQJ99AKACYeBjFXJ3w3AAAYACOGBGDG';
const REGION = 'eastus';

const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
    credentials: {
        region: REGION,
        subscriptionKey: SUBSCRIPTION_KEY
    }
});
SpeechRecognition.applyPolyfill(AzureSpeechRecognition);

export default function useSpeech() {
    const { browserSupportsSpeechRecognition, listening, transcript, interimTranscript, finalTranscript, resetTranscript, isMicrophoneAvailable } = useSpeechRecognition();

    useEffect(() => {
        return () => {
            SpeechRecognition.abortListening();
        };
    }, []);

    const toggleListening = () => {
        if (listening) {
            SpeechRecognition.abortListening();
        } else {
            SpeechRecognition.startListening();
        }
    };

    return {
        isListening: listening,
        toggleListening,
        startListening: () => SpeechRecognition.startListening(),
        stopListening: () => SpeechRecognition.abortListening(),
        transcript,
        micAvailable: isMicrophoneAvailable,
        browserHasVoice: browserSupportsSpeechRecognition,
        resetTranscript,
        interimTranscript,
        finalTranscript
    };
}
