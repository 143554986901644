// @ts-check

import { Box, Stack, Typography } from '@mui/material';

import { useGSFilter, useGSSearch } from 'Lib/hooks/useParam';
import Modal, { ModalClose, ModalHeader } from 'Lib/muiComponents/modal';

import GlobalSearch from '../globalSearch';

export default function ModalContainer() {
    const search = useGSSearch();
    const filter = useGSFilter();

    const handleClose = () => {
        search.removeMany([search.label, search.value, filter.label]);
    };

    return (
        <Modal
            name="Global Search"
            maxWidth="sm"
            modalSX={{ justifyContent: 'flex-start' }}
            containerSX={{ height: [3 / 4, 3 / 4, 'auto'], maxHeight: 1 }}
            paperSX={{ borderRadius: [0, 0, 6] }}
            onClose={handleClose}
        >
            <GlobalSearch />
        </Modal>
    );
}

ModalContainer.Header = function Header({ children }) {
    return (
        <Box sx={{ p: 8, width: '100%' }}>
            <ModalHeader
                sx={{
                    width: '100%',
                    padding: '0 !important'
                }}
            >
                <ModalClose sx={{ color: 'common.black', padding: '0 !important', ml: 'auto', display: ['block', 'block', 'block', 'none'] }} />
            </ModalHeader>
            {children}
        </Box>
    );
};

ModalContainer.Body = function Body({ children }) {
    return (
        <Stack direction="column" gap={3} alignItems="stretch" sx={{ flex: '1 0', minHeight: '0px', overflow: 'auto' }}>
            {children}
        </Stack>
    );
};

ModalContainer.Footer = function Footer({ children }) {
    return (
        <Box sx={{ px: 8, py: 4 }}>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={4}>
                {children}
            </Stack>
        </Box>
    );
};
