// @ts-check

import { Button, Chip, Container, Icon, styled } from '@mui/material';

export const CollectionLabel = styled(Container)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    margin: `${theme.spacing(4)} 0 ${theme.spacing(1)} 0`,
    backgroundColor: theme.palette.common.accentgray,
    color: 'white',
    borderRadius: '5px',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    fontWeight: '700'
}));

export const CollectionViewMore = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    margin: `${theme.spacing(1)} 0`,
    backgroundColor: theme.palette.common.lightblue200,
    color: theme.palette.common.darkblue,
    fontWeight: '700',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.common.lightblue200}`,
    transition: 'all 0.3s ease',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0), 0px 3px 8px 2px rgba(0, 0, 0, 0)',
    '&:hover': {
        backgroundColor: `${theme.palette.common.lightblue200}`,
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 2px rgba(0, 0, 0, .1)',

        border: `1px solid ${theme.palette.common.blue}`
    },
    '&:focus': {
        backgroundColor: `${theme.palette.common.lightblue200}`,
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 2px rgba(0, 0, 0, .1)',

        border: `1px solid ${theme.palette.common.blue}`
    }
}));

export const CircleIcon = styled(Icon)(({ theme }) => ({
    backgroundColor: theme.palette.disabled.main,
    width: theme.spacing(10),
    height: theme.spacing(10),
    lineHeight: theme.spacing(10),
    fontWeight: 'bold',
    borderRadius: '50%'
}));

export const ResultCard = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: '10px',

    position: 'relative',
    zIndex: '0'
}));

export const SearchChip = styled(Chip)(({ theme, ...props }) => ({
    backgroundColor: (!props.variant || props.variant === 'default') && theme.palette.disabled.main,
    padding: '2px 8px',
    height: 'unset',
    fontWeight: '700',
    fontSize: theme.typography.pxToRem(11),
    borderRadius: '3px',
    border: 0,
    '& .MuiChip-label': { padding: '0' }
}));

export const XrayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
            <path
                d="M3.5 2.00003L3.75 1.50002L4.25 1.25001L3.75 1.00001L3.5 0.5L3.25 1.00001L2.75 1.25001L3.25 1.50002L3.5 2.00003ZM1.25 3.00005L1.66656 2.16675L2.5 1.75002L1.66656 1.3333L1.25 0.5L0.833438 1.3333L0 1.75002L0.833438 2.16675L1.25 3.00005ZM6.75 5.00009L6.33344 5.83339L5.5 6.25011L6.33344 6.66684L6.75 7.50014L7.16656 6.66684L8 6.25011L7.16656 5.83339L6.75 5.00009ZM7.85344 1.97237L6.52766 0.646565C6.43016 0.548751 6.30219 0.5 6.17422 0.5C6.04625 0.5 5.91828 0.548751 5.82062 0.646565L0.146563 6.32074C-0.04875 6.51606 -0.04875 6.83262 0.146563 7.02778L1.47234 8.35359C1.57 8.45125 1.69797 8.5 1.82578 8.5C1.95375 8.5 2.08172 8.45125 2.17937 8.35359L7.85344 2.67926C8.04875 2.48426 8.04875 2.16753 7.85344 1.97237ZM5.61641 3.67912L4.82094 2.88364L6.17406 1.53049L6.96953 2.32597L5.61641 3.67912Z"
                fill="black"
            />
        </svg>
    );
};
