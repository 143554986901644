import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';


gsap.registerPlugin(useGSAP);

export default function SpinnyMcGradient() {
    const backgroundGradient = useRef(null);
    const parent = useRef();
    const tl = useRef();

    useGSAP(() => {
        gsap.to(backgroundGradient.current, {
            rotation: 360,
            repeat: -1,
            duration: 4,
            transformOrigin: 'center center',
            ease: 'linear'
        });
        tl.current = gsap
            .timeline()
            .from('.star1', {
                duration: 1,
                y: '20px',
                stagger: 0.2,
                opacity: 0,
                transformOrigin: 'center center',
                ease: 'power4.out',
                scale: 0.5
            })
            .to('.star1', {
                y: '10px',
                stagger: 0.2,
                ease: 'power1.inOut',
                duration: 2,
                yoyo: true,
                repeat: -1
            });
    }, [parent]);

    return (
        <svg ref={parent} width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="myMask">
                <path className="star1" d="m54.75,42.58l-3.8,8.36-8.36,3.8,8.36,3.8,3.8,8.36,3.8-8.36,8.36-3.8-8.36-3.8-3.8-8.36Z" fill="white" strokeWidth="0" />
                <path className="star1" d="m54.75,24.33l3.8-8.36,8.36-3.8-8.36-3.8-3.8-8.36-3.8,8.36-8.36,3.8,8.36,3.8,3.8,8.36Z" fill="white" strokeWidth="0" />
                <path
                    className="star1"
                    d="m31.94,25.85l-7.6-16.73-7.6,16.73L0,33.46l16.73,7.6,7.6,16.73,7.6-16.73,16.73-7.6-16.73-7.6Zm-4.59,10.62l-3.01,6.63-3.01-6.63-6.63-3.01,6.63-3.01,3.01-6.63,3.01,6.63,6.63,3.01-6.63,3.01Z"
                    fill="white"
                    strokeWidth="0"
                />
            </mask>

            <g mask="url(#myMask)" width="100%" height="100%">
                <rect x="0" y="0" width="100%" height="100%" fill="#02BB57" />
                <g width="150%" height="150%" ref={backgroundGradient}>
                    <circle cx="30%" cy="60%" r="80%" fill="url('#grad')" transform="skewX(50)" />
                    <circle cx="40%" cy="30%" r="60%" fill="url('#grad')" transform="skewX(-15)" />
                    <circle cx="-10%" cy="75%" r="50%" fill="url('#grad')" transform="skewX(10)" />
                </g>
            </g>

            <defs>
                <radialGradient id="grad">
                    <stop offset="0" stopColor="#28517E" />
                    <stop offset="30%" stopColor="#28517E" stopOpacity=".8" />

                    <stop offset="1" stopColor="#28517E" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
}
