// @ts-check
import { useQuery } from '@tanstack/react-query';

import useAIAssistant from '../components/search/Params';

const aiKey = 'search/assistant';

export const useGenAISearch = () => {
    // get the search term from the URL

    const search = useAIAssistant();
    const isQueryEnabled = search.value.length > 1;

    return useQuery({
        queryKey: [aiKey, { input: search.value }],
        enabled: isQueryEnabled,
        staleTime: 0
    });
};
