// @ts-check

import { Box, Stack, Typography } from '@mui/material';

import Modal, { ModalClose, ModalHeader } from 'Lib/muiComponents/modal';

import AiAssist from '../aiAssist';

export default function ModalContainer() {
    return (
        <Modal
            name="Ai Assist"
            maxWidth="sm"
            modalSX={{ justifyContent: 'flex-start' }}
            containerSX={{ height: [3 / 4, 3 / 4, 'auto'], maxHeight: 1 }}
            paperSX={{ borderRadius: [0, 0, 6] }}
        >
            <AiAssist />
        </Modal>
    );
}

ModalContainer.Header = function Header({ children }) {
    return (
        <Box sx={{ p: 8, width: '100%' }}>
            <ModalHeader
                sx={{
                    width: '100%',
                    padding: '0 !important'
                }}
            >
                <Typography component="h2" sx={{ fontSize: '1.25rem' }}>
                    Assistant
                </Typography>
                <ModalClose sx={{ color: 'common.black', padding: '0 !important', ml: 'auto', display: ['block', 'block', 'block', 'none'] }} />
            </ModalHeader>
            {children}
        </Box>
    );
};

ModalContainer.Body = function Body({ children }) {
    return (
        <Stack direction="column" gap={3} alignItems="stretch" sx={{ flex: '1 0', minHeight: '0px', overflow: 'auto' }}>
            {children}
        </Stack>
    );
};
