// @ts-check
import useParam from 'Lib/hooks/useParam';
import { useModalTrigger } from 'Lib/muiComponents/modal/ModalTrigger';

export default function useAIAssistant() {
    return useParam('_useAIAssistant');
}

export function useAIAssistantModal() {
    const modal = useModalTrigger();

    return () => modal.setValue('Ai Assist', null, 'push');
}
