// @ts-check
import Card, { Body, DeepLink, Description } from '../CardComponents';

import NavigationCategories from '~/components/globalSearch/globalSearch/data/navigationCategories';
/** @param {{result: NavigationResult}} param0 */
export default function Navigation({ result }) {
    const cardType = 'Navigate';

    const { data, xray, highlights } = result;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];

    return (
        <Card.Parent path={data.url} cardType={cardType}>
            <Card.Content>
                <Card.Header
                    title={data.name}
                    highlight={highlightedName}
                    cardType={cardType}
                    subtype={data.navigationCategoryID && NavigationCategories.getName(data.navigationCategoryID)}
                />

                <Body>
                    <Description>{data.description}</Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
