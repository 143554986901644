// @ts-check

import { useEffect, useRef, useState } from 'react';
import { ButtonBase, Chip, Collapse, Icon, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { v4 as UUID } from 'uuid';

import useDebounce from 'Lib/hooks/useDebounce';
import stopPropagation from 'Lib/utilities/stopPropagation';
import userDevice from 'Lib/utilities/userDevice';

import { filterChips } from '../../data/indexTypes';

import { useGSFilter, useGSSearch } from './Params';
import useVoiceRecognition from 'Lib/hooks/useVoiceRecognition';

export default function Input() {
    const { isListening, transcript, stopListening, startListening, micAvailable, browserHasVoice } = useVoiceRecognition();
    const inputRef = useRef(null);

    const filter = useGSFilter();
    const search = useGSSearch();

    const [searchInput, setSearchInput] = useState(search.value);

    const debouncedValue = useDebounce(searchInput, 450);

    const activeFilter = filterChips.filter(chip => chip.value === filter.value)[0];

    useEffect(() => {
        // If genAISearch is populated via voice, automatically submit the search phrase. This is normally an explicit action when typing a search.
        if (debouncedValue === search.value) return;
        search.setValue(debouncedValue);
    }, [debouncedValue]);

    useEffect(() => {
        setSearchInput(transcript);
    }, [transcript]);

    const clearSearch = () => {
        setSearchInput('');
        search.removeValue();
        filter.removeValue();
    };

    const { os } = userDevice();

    // when active filter changes, refocus on the input
    useEffect(() => {
        if (activeFilter) {
            inputRef?.current?.querySelector('input').focus();
        }
    }, [activeFilter]);

    const handleKeydown = e => {
        // keyCode 13 represents the Enter/Return button for mobile purposes
        if (e.key === 'Backspace' && !e.currentTarget.value) {
            clearSearch();
        }
    };

    const handleSubmit = () => {
        search.setValue(searchInput);
    };

    return (
        <TextField
            autoFocus
            required
            fullWidth
            ref={inputRef}
            className="global-search_search-input"
            id={UUID()}
            value={searchInput}
            data-cy="table-search-input"
            variant="outlined"
            placeholder="Search"
            aria-label="Search"
            onChange={event => setSearchInput(event.target.value)}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSubmit();
                }
            }}
            InputProps={{
                className: 'global-search_primary-link',
                onKeyDown: handleKeydown,
                startAdornment: <InputStartAdornment activeFilter={activeFilter} onClearFilter={filter.removeValue} />,
                endAdornment: (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {searchInput !== '' ? (
                            <IconButton
                                aria-label="clear search"
                                onClick={e => {
                                    e.stopPropagation();
                                    clearSearch();

                                    // set focus on this elements inner input
                                    inputRef?.current?.querySelector('input').focus();
                                }}
                            >
                                cancel
                            </IconButton>
                        ) : (
                            browserHasVoice && (
                                <ButtonBase
                                    disabled={!micAvailable}
                                    sx={{ bgcolor: isListening ? 'red' : 'white', px: 1, py: 1, borderRadius: '24px' }}
                                    onClick={isListening ? stopListening : startListening}
                                >
                                    <Icon sx={{ color: isListening ? 'white' : 'common.gray', p: 0 }}>{isListening ? 'mic' : 'mic_off'}</Icon>
                                </ButtonBase>
                            )
                        )}
                    </>
                )
            }}
        />
    );
}

/** @param {{activeFilter: ChipFilter, onClearFilter: () => void}} props */
function InputStartAdornment({ activeFilter, onClearFilter }) {
    return (
        <InputAdornment position="start">
            <Stack direction="row" gap={2} alignItems="center">
                <Icon sx={{ display: { xxs: activeFilter ? 'none' : 'block', sm: 'block' } }}>search</Icon>
                <Collapse in={Boolean(activeFilter)} orientation="horizontal" collapsedSize={0} timeout={100}>
                    {activeFilter ? (
                        <Chip
                            sx={{ fontSize: theme => theme.typography.pxToRem(12), lineHeight: '1' }}
                            label={activeFilter.label}
                            // clickable
                            onDelete={e => {
                                stopPropagation(e);
                                onClearFilter();
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </Collapse>
            </Stack>
        </InputAdornment>
    );
}
