// @ts-check

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, ButtonBase, Icon, Stack, TextField } from '@mui/material';

import useControlledInput from 'Lib/hooks/useControlledInput';
import { screenSize } from 'Lib/utilities/screenSize';

import { useGenAISearch } from '../../data/useGenAISearch';

import useAIAssistant from './Params';

export function Input({ enabled, mode, isVoice, voice, setMode }) {
    const { isListening, transcript, stopListening, startListening, micAvailable, browserHasVoice } = voice;
    const history = useHistory();

    const { inputRef, inputValue, setInputValue, focusInput } = useControlledInput();
    const { data: genAIData, isSuccess: aiSuccess, isFetching } = useGenAISearch();
    const search = useAIAssistant();
    const timeout = handleAIResponse(search, history, genAIData);

    const { mobile } = screenSize();

    const searchIsEmpty = search.value === '';
    const inputIsEmpty = inputValue === '';
    const transcriptIsEmpty = transcript === '';

    if (!browserHasVoice) {
        setMode('keyboard');
    }

    // when the mode changes to voice, start listening and clear the input
    useEffect(() => {
        if (isVoice && micAvailable) {
            startListening();
            setInputValue('');
            focusInput();
        }
    }, [mode]);

    // when enabled changes to true and the mode is voice and search is empty and the input value is not empty, then start listening and clear the input
    useEffect(() => {
        if (enabled && isVoice && searchIsEmpty && !inputIsEmpty) {
            startListening();
            setInputValue('');
        }
    }, [enabled]);

    useEffect(() => {
        setInputValue(transcript);
    }, [transcript]);

    // on mount, focus the input
    useEffect(() => {
        if (search.isSet) {
            search.removeValue();
        }
        setInputValue('');

        focusInput();
    }, []);

    // if listening was true and then becomes false, and the inputValue is not an empty string, then we want to submit the search, but don't submit the search
    useEffect(() => {
        if (!isListening) {
            setMode('keyboard');
            if (!transcriptIsEmpty) {
                search.setValue(inputValue);
            }
        } else {
            setMode('voice');
        }
    }, [isListening]);

    // if data exists and data.link exists, toastSuccess timeout 2 seconds then navigate to link
    useEffect(() => {
        if (aiSuccess && genAIData && !isFetching && !searchIsEmpty) {
            if (genAIData.link) {
                timeout();
            }
        }
    }, [genAIData]);

    return (
        <>
            <TextField
                autoFocus
                inputRef={inputRef}
                required
                fullWidth
                multiline
                minRows={mobile ? 2 : 6}
                maxRows={mobile ? 2 : 6}
                value={inputValue}
                data-cy="table-search-input"
                variant="outlined"
                placeholder={isListening ? 'Speak command...' : 'Enable mic or type command'}
                aria-label={'AI Assistant ' + isVoice ? 'Voice' : 'Text' + ' Input'}
                onChange={event => {
                    setInputValue(event.target.value);
                }}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        search.setValue(inputValue);
                    }
                }}
                InputProps={{
                    sx: {
                        alignItems: 'stretch'
                    },
                    startAdornment: browserHasVoice && (
                        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ minHeight: 0 }}>
                            <ButtonBase
                                disabled={!micAvailable}
                                sx={{ bgcolor: isListening ? 'red' : 'white', px: 1, py: 1, left: '-5px', top: '-5px', borderRadius: '24px' }}
                                onClick={isListening ? stopListening : startListening}
                            >
                                <Icon sx={{ color: isListening ? 'white' : 'common.gray', p: 0 }}>{isListening ? 'mic' : 'mic_off'}</Icon>
                            </ButtonBase>
                        </Stack>
                    )
                }}
            />
            <Button disabled={!enabled || inputValue === '' || isFetching} onClick={() => search.setValue(inputValue)} variant="primary">
                Submit
            </Button>
        </>
    );
}

function handleAIResponse(search, history, genAIData) {
    return async () => {
        await setTimeout(() => {}, 2000);
        if (search.value !== '') {
            history.replace(genAIData.link);
            toast.success(() => (
                <Box>
                    <span>Assisted</span>
                    <ul>
                        {genAIData.matchFields.map((field, i) => {
                            return (
                                <li key={i}>
                                    {field.label}: <strong>{field.value}</strong>
                                </li>
                            );
                        })}
                    </ul>
                </Box>
            ));
        }
    };
}
