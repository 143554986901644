// @ts-check

import { useState } from 'react';
import { Box, Chip, Collapse, Icon, IconButton, Stack, Tooltip, useTheme } from '@mui/material';

import { filterChips } from '../../data/indexTypes';
import useGlobalSearch from '../../data/useGlobalSearch';

import Toggle from 'Lib/components/form/Toggle';

import { useGSFilter, useGSSearch, useGSIncludeInactive } from './Params';

export default function Filters() {
    const { data, isSuccess } = useGlobalSearch();

    const theme = useTheme();

    const filter = useGSFilter();
    const includeInactive = useGSIncludeInactive();

    const activeFilter = filterChips.find(chip => chip.value === filter.value);

    let chips = filterChips;

    if (isSuccess && data.pages[0].count && data.pages[0].count !== 0) {
        chips = data.pages[0].chips
            .map(chip => {
                const matchingChip = data.pages[0].chips.find(returnedChip => returnedChip.value === chip.value);

                return {
                    ...chip,
                    resultCount: matchingChip?.resultCount ?? 0
                };
            })
            .filter(chip => {
                if (filter.isSet) return true;

                return chip.resultCount !== 0;
            });
    }

    const [open, setOpen] = useState(false);
    const [inactiveToggleInDrawer, setInactiveToggleInDrawer] = useState(!includeInactive.value);

    const toggleOpen = () => {
        setInactiveToggleInDrawer(true);

        //If the toggle is activated and the drawer is being closed, do not hide the toggle in the drawer
        if (includeInactive.value && open) setInactiveToggleInDrawer(false);

        setOpen(prevState => !prevState);
    };

    // Chip should only close the filters window if it is open
    /** @type {(arg0: ChipFilter) => void} handleChipClick */
    const handleChipClick = chip => {
        if (open) setOpen(false);
        filter.setValue(chip.value);
    };

    const handleInactiveToggle = () => {
        if (includeInactive.value) {
            includeInactive.removeValue();
        } else {
            includeInactive.setValue('true');
        }
    };

    const InactiveToggle = () => (
        <Stack direction="row" alignItems="center" gap={1}>
            <Toggle name="includeInactive" onChange={handleInactiveToggle} value={includeInactive.value} style={{ margin: '0', padding: '0' }} />
            <Box>Inactive</Box>
            {!includeInactive.value && (
                <Tooltip placement="top" arrow title="Include inactive portfolios, properties, units, leases, vendors and voided accounting transactions">
                    <Icon>info</Icon>
                </Tooltip>
            )}
        </Stack>
    );

    if (activeFilter) return;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ maxWidth: '100%' }}>
            <Box sx={{ maxWidth: '100%', minWidth: '0px', pt: 2 }}>
                <Collapse in={open} collapsedSize={32}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
                        {!inactiveToggleInDrawer && <InactiveToggle />}
                        {chips.map((chip, index) => (
                            <Chip
                                sx={{ overflow: 'visible', fontSize: theme.typography.pxToRem(12), lineHeight: '1.4' }}
                                key={index}
                                label={`${chip.label} ${chip.resultCount !== 0 ? `(${chip.resultCount})` : ''}`}
                                onClick={() => handleChipClick(chip)}
                                disabled={chip.value === activeFilter?.value}
                            />
                        ))}
                        {inactiveToggleInDrawer && <InactiveToggle />}
                    </Box>
                </Collapse>
            </Box>
            <Box sx={{ flex: '0 1 auto' }}>
                <IconButton sx={{}} onClick={toggleOpen}>
                    filter_list
                </IconButton>
            </Box>
        </Stack>
    );
}
