// @ts-check

import { useCallback, useRef, useState } from 'react';

import useDebounce from './useDebounce';

export default function useControlledInput(debouncedTime = 450) {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    // use callback focus input when inputRef changes

    const focusInput = useCallback(() => {
        const ref = inputRef?.current;
        if (ref) {
            ref.focus();
        }
    }, [inputRef]);

    const debouncedValue = useDebounce(inputValue, debouncedTime);

    return {
        inputRef,
        inputValue,
        setInputValue,
        focusInput,
        debouncedValue
    };
}
