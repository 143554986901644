// @ts-check

import { useState } from 'react';

import useVoiceRecognition from 'Lib/hooks/useVoiceRecognition';

import ModalContainer from '../modal';

import Results from './components/results/AIResults';
import { Input } from './components/search/Input';

export default function Index() {
    const [mode, setMode] = useState('keyboard');
    const [enabled, setEnabled] = useState(true);
    const voice = useVoiceRecognition();

    const isVoice = mode === 'voice';

    return (
        <>
            <ModalContainer.Header>
                <Input enabled={enabled} mode={mode} isVoice={isVoice} voice={voice} setMode={setMode} />
            </ModalContainer.Header>
            <ModalContainer.Body>
                <Results mode={mode} enabled={enabled} setEnabled={setEnabled} isVoice={isVoice} />
            </ModalContainer.Body>
        </>
    );
}
