// @ts-check

import { useRef } from 'react';
import { Box, Icon, Skeleton, Stack, Typography } from '@mui/material';

import useNavigateKeyboard from 'Lib/hooks/useKeyboardNavigation';
import { screenSize } from 'Lib/utilities/screenSize';

import useGlobalSearch, { useSearchResults } from '../globalSearch/data/useGlobalSearch';
import ModalContainer from '../modal';

import Results from './components/results/ResultsList';
import Filters from './components/search/Filters';
import GSInput from './components/search/Input';
import { useGSSearch } from './components/search/Params';

export default function Index() {
    return (
        <KeyboardNavContainer>
            <ModalContainer.Header>
                <GSInput />
                <Filters />
            </ModalContainer.Header>
            <ModalContainer.Body>
                <ResultsContainer>
                    <Results />
                </ResultsContainer>
            </ModalContainer.Body>
            <ModalContainer.Footer>
                <ModalFooter />
            </ModalContainer.Footer>
        </KeyboardNavContainer>
    );
}

function ModalFooter() {
    const search = useGSSearch();
    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const { data, isLoading } = useSearchResults();

    const isDesktop = !isTabletScreenSize && !isMobileScreenSize;

    return (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={4}>
            {isDesktop && (
                <Stack direction="row" justifyContent={'start'} alignItems="center" gap={4} sx={{ flexGrow: 1 }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Icon variant="keyboard">north</Icon>
                        <Icon variant="keyboard">south</Icon>
                        <Box sx={{ fontSize: '12px' }}>to navigate</Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Icon variant="keyboard">keyboard_return</Icon>
                        <Box sx={{ fontSize: '12px' }}>to select</Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Icon variant="keyboard" sx={{ width: 'unset' }}>
                            <Box sx={{ fontSize: '12px', fontFamily: 'sans-serif', lineHeight: '17px', px: 1 }}>esc</Box>
                        </Icon>
                        <Box sx={{ fontSize: '12px' }}>to close</Box>
                    </Stack>
                </Stack>
            )}

            <Stack direction="row" justifyContent={isDesktop ? 'end' : 'center'} alignItems="center" gap={4} sx={{ flexGrow: 1 }}>
                {isLoading ? (
                    <Skeleton variant="rectangular" width="120" height="80" sx={{ flexGrow: 1 }} />
                ) : (
                    <Typography component="span" sx={{ fontWeight: '700', fontSize: '12px', color: 'common.blue' }}>
                        {search.isSet ? (
                            <>
                                Showing {data?.results?.length} results of {data?.totalResults}
                            </>
                        ) : (
                            <>{data?.totalResults && data?.totalResults !== 0 && <>{data?.totalResults} Results</>}</>
                        )}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

const focusInput = parentRef => {
    const input = parentRef?.current.querySelector('.global-search_search-input').querySelector('input');
    if (input) {
        input.focus();
    }
};

function KeyboardNavContainer({ children }) {
    const { data } = useGlobalSearch();
    const parentRef = useRef(null);

    useNavigateKeyboard(parentRef, '.global-search_primary-link', [data]);

    // add event listener to parentRef. If Cmd/Ctrl + K is pressed, focus on the input field
    if (parentRef.current) {
        parentRef.current.addEventListener('keydown', e => {
            if (e.metaKey && e.code === 'KeyK') {
                e.stopPropagation();
                e.preventDefault();
                focusInput(parentRef);
            }
        });
    }

    return (
        <Stack direction="column" justifyContent="stretch" alignItems="stretch" sx={{ flex: '1', maxHeight: '100%' }} ref={parentRef}>
            {children}
        </Stack>
    );
}

export function ResultsContainer({ children }) {
    return (
        <Stack direction="column" gap={3} alignItems="stretch" sx={{ flex: '1 0', minHeight: '0px', overflow: 'auto' }}>
            {children}
        </Stack>
    );
}
