import queryString from 'query-string';

import PortalTypes from 'Lib/constants/app/portalTypes';
import { now } from 'Lib/utilities/datetime';

import * as actions from './actions';
import { initialState } from './reducer';

import agent from '~/agent';
import store from '~/store';

export const LOGIN_URL = 'session_manager_LoginUrl';

const SESSION_TIMEOUT_THRESHOLD = 300; // Refresh Access Token for 5 minutes before it expires

const sessionTimeout = null;

const ROOT_DOMAIN = CONFIG.domain;
const CLIENT_ID = CONFIG.clientId;

export const getSubdomain = () => {
    // window.location.host is subdomain.domain.com
    const full = window.location.host;
    const parts = full.split('.');

    return parts[0];
};

const clearSession = () => {
    clearTimeout(sessionTimeout);
    // dispatch action to clear the session
    store.dispatch(actions.update(initialState));
};

const setSessionTimeout = duration => {
    clearTimeout(sessionTimeout);

    // sessionTimeout = setTimeout(() => {
    return agent.refreshToken().then(onAuthSuccess).catch(onAuthFailed);
    // }, (duration - SESSION_TIMEOUT_THRESHOLD) * 1000);
};

const onAuthSuccess = response => {
    store.dispatch(actions.update({ isAuthenticated: true }));
    // setSessionTimeout(response.data.expires);
};

const onAuthFailed = exception => {
    clearSession();
    throw exception;
};

const setLoginUrl = () => {
    // Omitting redirect as it's strictly used for the login action below to set the login URL in certain instances
    const { redirect, ...qs } = queryString.parse(window.location.search);
    let pathname = window.location.pathname;

    // If redirect is present, then we need to set the login url
    if (redirect) {
        try {
            const tmpRedirectUrl = new URL(window.location.origin + redirect);

            pathname = tmpRedirectUrl.pathname;
        } catch {
            // the redirect was not a valid pathname with search
        }
    }

    const path = JSON.stringify({
        pathname,
        search: queryString.stringify(qs),
        logoutDateTime: now()
    });

    window.localStorage.setItem(LOGIN_URL, path);
};

export const getLoginUrl = () => {
    return window.localStorage.getItem(LOGIN_URL);
};

export const clearLoginUrl = () => {
    window.localStorage.removeItem(LOGIN_URL);
};

export const login = () => {
    setLoginUrl();

    // OAuth Login
    const oauthQs = queryString.stringify({
        client_id: CLIENT_ID,
        response_type: 'code',
        subdomain: getSubdomain()
    });

    window.location.assign(`https://account${ROOT_DOMAIN}/oauth2/authorize?${oauthQs}`);
};

export const requestToken = code => {
    return agent.requestToken({ code }).then(onAuthSuccess).catch(onAuthFailed);
};

export const refreshToken = () => {
    // return agent.refreshToken().then(onAuthSuccess).catch(onAuthFailed);
};

export const checkForSession = () => {
    store.dispatch(actions.update({ isAuthenticated: true }));
};

export const logout = () => {
    setLoginUrl();

    // OAuth Login
    const oauthQs = queryString.stringify({
        portalID: PortalTypes.Manager,
        subdomain: getSubdomain()
    });

    return agent
        .revokeToken()
        .then(() => {
            clearSession();
        })
        .finally(() => {
            window.location.assign(`https://account${ROOT_DOMAIN}/oauth2/logout?${oauthQs}`);
        });
};

export const sendMFACode = (data = null) => {
    agent.request.post('two-factor/send', data);
};

export const emailMFACode = (data = null) => {
    sendMFACode({ ...data, type: 'email' });
};
