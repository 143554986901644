// @ts-check

import { Box, Button, List, ListItem, Stack, Typography } from '@mui/material';

import EmptyView from 'Lib/components/common/table/EmptyView';
import SpinnyMcGradient from 'Lib/components/loaders/AutoVine/AILoader';

import { useGenAISearch } from '../../data/useGenAISearch';
import useAIAssistant from '../search/Params';

export default function AIResults({ enabled, setEnabled, isVoice }) {
    const { isError: aiError, isSuccess, isFetching } = useGenAISearch();
    const search = useAIAssistant();

    // AI Error
    if (aiError && search.value !== '') {
        return (
            <ResultWrapper align="center" justify="center">
                <EmptyView backgroundColor="transparent" iconName={'chat'} title="No Results" subTitle="Rentvine Assist command was not recognized" />
            </ResultWrapper>
        );
    }

    if (search.value !== '' || isFetching || isSuccess) {
        return (
            <ResultWrapper align="center" justify="center">
                <Box sx={{ py: 4 }}>
                    <Box sx={{ pb: 6 }}>Assisting...</Box>
                    <Box sx={{ transform: 'scale(1.4)', width: '100px', height: '100px' }}>
                        <SpinnyMcGradient />
                    </Box>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            search.setValue('');
                            setEnabled(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </ResultWrapper>
        );
    }

    if (!enabled) {
        return (
            <ResultWrapper align="center" justify="center">
                <Box sx={{ py: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ my: 6 }}>Assist Canceled</Box>

                    <Button variant="text" onClick={() => setEnabled(true)}>
                        Try Again?
                    </Button>
                </Box>
            </ResultWrapper>
        );
    }

    return (
        <ResultWrapper>
            <Box>
                <Box>
                    Start a task by <strong>{isVoice ? 'Saying' : 'Typing'}</strong>...
                </Box>
                <List sx={{ fontSize: '0.975rem' }}>
                    <ListItem sx={{ display: 'inline-flex', gap: 1 }}>
                        <strong>Add Bill:</strong> &rdquo;Create a bill for [vendor] for [amount] due [date].&rdquo;
                    </ListItem>
                    <ListItem sx={{ display: 'inline-flex', gap: 1 }}>
                        <strong>New Lease Charge:</strong> &rdquo;Create a Lease Charge for [amount] for [tenant] for [description].&rdquo;
                    </ListItem>
                    <ListItem sx={{ display: 'inline-flex', gap: 1 }}>
                        <strong>Record Tenant Receipt:</strong> &rdquo;Record a payment of [amount] from [tenant].&rdquo;
                    </ListItem>
                    <ListItem sx={{ display: 'inline-flex', gap: 1 }}>
                        <strong>Record Owner Receipt:</strong> &rdquo;Record a contribution of [amount] from [owner].&rdquo;
                    </ListItem>
                    <ListItem sx={{ display: 'inline-flex', gap: 1 }}>
                        <strong>Add Tenant Credit:</strong> &rdquo;Add a credit of [amount] to [address].&rdquo;
                    </ListItem>
                </List>
                <Typography>Welcome to Rentvine Assist! Right now, Rentvine Assist can help you with the tasks above. Please note, more actions are coming soon!</Typography>
            </Box>
        </ResultWrapper>
    );
}

const ResultWrapper = ({ children, align = 'stretch', justify = 'start' }) => {
    return (
        <Stack direction="column" gap={2} alignItems={align} justifyContent={justify} sx={{ flex: '1 0', px: 8, py: 8, bgcolor: 'common.darkgray' }}>
            {children}{' '}
        </Stack>
    );
};
